body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home {
  background-color: black;
}

.admin {
  background-color: white;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'FranklinGothic';
  src: url('../fonts/URW Franklin Gothic W01 Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

.name {
  font-family: 'FranklinGothic', sans-serif;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 1;
  transition: opacity 1s linear; /* Adjust the duration here (e.g., 1s for 1 second) */
}



  /* Add this CSS to your existing CSS file or create a new CSS file */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999; /* Ensure it's above other content */
  }
  
  .spinner {
    /* Your spinner styling here */
    /* Example: */
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #2dccd3;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: spin 1s linear infinite;
    z-index: 9999999; /* Ensure it's above other content */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes catRun {
    from {
      background-position: 0;
    }
    to {
      background-position: -1160px;
    }
  }
  
/* Add this CSS to your existing CSS file or create a new CSS file */
.loading-text {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 60px;
  position: fixed;
  margin-top: 250px;
  font-size: 30px;
  color: #54575a;
  z-index: 99999999; /* Ensure it's above other content */
}

.loading-text::after {
  content: ".";
  animation: ellipsis 1.25s infinite steps(3);
}

@keyframes ellipsis {
  0% { content: "."; }
  20% { content: ".."; }
  40% { content: "..."; }
  60% { content: "...."; }
  80% { content: "....."; }
  100% { content: "......"; }
}


.logo-container {
  position: absolute;
}

.mobile-logo {
  max-width: 200px; /* Adjust the size as needed */
  height: auto; /* Maintain the aspect ratio */
}