.section-container {
    margin-bottom: 20px; /* Adjust margin as needed */
    border: 0.1px solid #ccc; /* Border style */
    border-radius: 5px; /* Rounded corners */
    padding: 15px; /* Padding inside the container */
    background-color: #f9f9f9; /* Background color */
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1); /* Shadow effect */
  }
  
  /* Additional styling for h5 headers within section-container */
  .section-container h5 {
    margin-top: 0; /* Remove margin at the top of h5 */
    font-weight: bold; /* Make the headers bold */
    margin-bottom: 10px; /* Add space after headers */
  }
  
  .ql-editor {
    background:white;
  }

  button.react-datepicker__navigation {
    display: flex;
    border: none;
    background: none;
  }

  button.react-datepicker__navigation:hover {
    background: none; /* Set the background to none on hover */
  }

  .react-datepicker-wrapper {
    width: 80%;
  }

  .react-datepicker__input-container input {
    width: 100% !important;
  }